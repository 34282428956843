import React, { useRef, useState, useEffect, useMemo } from 'react';
import { Input } from '../../common/Input';
import { isEmptyString, validateEmail, validatePhoneNo } from './../../utils';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, createStyles, Grid, IconButton, makeStyles, styled, Theme, Typography } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { FcGoogle } from 'react-icons/fc';
import axios from 'axios';
import { PublicClientApplication } from '@azure/msal-browser';
import { MicroSoftIcon } from 'static/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hLoginFormOR: {
      marginTop: '10px !important',
      marginBottom: '10px !important',
      width: '100% !important',
      '&>p': {
        textAlign: 'center',
        position: 'relative',
        '&:before': {
          top: '50%',
          width: '80%',
          height: '1px',
          content: "''",
          position: 'absolute',
          transform: 'translate(-50%,-50%)',
          left: '50%',
          backgroundColor: '#28a745'
        },
        '&>span': {
          padding: '0 5px',
          position: 'relative',
          backgroundColor: '#fff'
        }
      }
    },
    loginButton: {
      display: 'flex',
      justifyContent: 'center',
      gap: '5px',
      paddingBottom: '15px'
    }
  })
);

const PreRegistrationForm = (props: any) => {
  const [token, setToken] = useState(null);
  const classes = useStyles();
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const {
    organization_ref_id,
    handleSubmit,
    inputsHandler,
    captchaRef,
    setRequiredField,
    inputField: { name_first, name_middle, name_last, phone, email_address, password, confirm_password, id },
    isRequiredField,
    setActive,
    setInputField,
    googleData
  } = props;
  let isPreRegistration = true;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  if (
    !isEmptyString(name_first) &&
    !isEmptyString(name_last) &&
    !validatePhoneNo(phone) &&
    !validateEmail(email_address) &&
    !isEmptyString(password) &&
    !isEmptyString(confirm_password) &&
    password === confirm_password &&
    password?.length >= 16
  ) {
    isPreRegistration = false;
  }
  const isDisabled = id !== '';

  const togglePasswordVisibility = async () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (credentialResponse: any) => {
      try {
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: {
            Authorization: `Bearer ${credentialResponse.access_token}`
          }
        });
        const { given_name, family_name, email } = userInfoResponse.data;
        setIsSubmitForm(true);
        googleData.current = {
          name_first: given_name,
          name_last: family_name,
          email_address: email,
          is_google_login: true,
          is_microsoft_login: false
        };
        handleSubmit(true);
      } catch (error) {
        throw new Error('Something went wrong');
      }
    },
    onError: () => {
      console.log('Login Failed');
    }
  });

  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID || ''
    }
  };

  const handleSsoLoginMicrosoft = async () => {
    try {
      const msalInstance = new PublicClientApplication(msalConfig);
      await msalInstance.initialize();
      const loginSuccess = await msalInstance.loginPopup({
        redirectUri: 'https://healthloqproducer-dev.azurewebsites.net',
        // redirectUri: 'http://localhost:3000',
        scopes: ['user.read']
      });
      const userInfoResponse = await axios.get('https://graph.microsoft.com/v1.0/me', {
        headers: {
          Authorization: `Bearer ${loginSuccess?.accessToken}`
        }
      });

      setIsSubmitForm(true);
      console.log(userInfoResponse.data);
      googleData.current = {
        name_first: userInfoResponse.data.givenName,
        name_last: userInfoResponse.data.surname,
        email_address: userInfoResponse.data.mail,
        is_microsoft_login: true,
        is_google_login: false
      };
      handleSubmit(true);
    } catch (error) {
      console.log('----------', error);
    }
  };

  useEffect(() => {
    const handleMessage = (event: any) => {
      if (event.data.type === 'success') {
        console.log(event.data.data);
        setIsSubmitForm(true);
        googleData.current = {
          name_first: event.data.data.givenName,
          name_last: event.data.data.surname,
          email_address: event.data.data.mail,
          is_microsoft_login: true,
          is_google_login: false
        };
        handleSubmit(true);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  const [isComponentLoaded, setIsComponentLoaded] = useState(false);

  useEffect(() => {
    const checkElement = () => {
      const element = document.getElementById('microsoft-sso-login-button');
      const elementClass = document.getElementsByClassName('mui-outlined-button');
      if (element && elementClass.length > 0) {
        setIsComponentLoaded(true);
      }
    };

    // Check immediately and add a mutation observer if necessary
    checkElement();

    const observer = new MutationObserver(() => {
      checkElement();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  return (
    <div className="section-pre-registration-form">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <label className="col-form-label">
              First Name<span>*</span>
            </label>
            <Input
              disabled={isDisabled}
              value={name_first}
              name="name_first"
              required={isSubmitForm && isEmptyString(name_first)}
              autoFocus
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(name_first) && <p className="error-message">Please enter a value</p>}
          </div>
          <div className="col-md-4 col-sm-12">
            <label className="col-form-label">Middle Name</label>
            <Input
              disabled={isDisabled}
              value={name_middle}
              name="name_middle"
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
          </div>
          <div className="col-md-4 col-sm-12">
            <label className="col-form-label">
              Last Name<span>*</span>
            </label>
            <Input
              value={name_last}
              disabled={isDisabled}
              name="name_last"
              required={isSubmitForm && isEmptyString(name_last)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && isEmptyString(name_last) && <p className="error-message">Please enter a value</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <label className="col-form-label">
              Phone<span>*</span>
            </label>
            <Input
              disabled={isDisabled}
              value={phone}
              name="phone"
              required={isSubmitForm && validatePhoneNo(phone)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && validatePhoneNo(phone) && <p className="error-message">Please enter valid Phone No.</p>}
          </div>
          <div className="col-md-6 col-sm-12">
            <label className="col-form-label">
              Email Address<span>*</span>
            </label>
            <Input
              disabled={isDisabled || organization_ref_id !== ''}
              value={email_address}
              name="email_address"
              type="email"
              required={isSubmitForm && validateEmail(email_address)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
            />
            {isSubmitForm && validateEmail(email_address) && (
              <p className="error-message">Please enter vaild email address</p>
            )}
          </div>
        </div>
        {!isDisabled && (
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <label className="col-form-label">
                Password<span>*</span>
              </label>
              <div style={{ position: 'relative', width: '100%' }}>
                <Input
                  value={password}
                  name="password"
                  required={isSubmitForm && (password !== confirm_password || isEmptyString(password))}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
                  type={showPassword ? 'text' : 'password'}
                />
                <IconButton
                  type="button"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '0',
                    fontSize: '14px'
                  }}
                >
                  {!showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </div>
              {isSubmitForm && (
                <p className="error-message">
                  {isEmptyString(password)
                    ? 'Please enter a value'
                    : password?.length < 16
                    ? 'Password must be 16 characters long.'
                    : password !== confirm_password
                    ? 'Your password and confirmation password do not match.'
                    : null}
                </p>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              <label className="col-form-label">
                Confirm Password<span>*</span>
              </label>
              <div style={{ position: 'relative', width: '100%' }}>
                <Input
                  value={confirm_password}
                  name="confirm_password"
                  required={isSubmitForm && (password !== confirm_password || isEmptyString(confirm_password))}
                  type={showConfirmPassword ? 'text' : 'password'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>): void => inputsHandler(event)}
                />
                <IconButton
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '0',
                    fontSize: '14px'
                  }}
                >
                  {!showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </div>
              {isSubmitForm && (
                <p className="error-message">
                  {isEmptyString(confirm_password)
                    ? 'Please enter a value'
                    : password !== confirm_password
                    ? 'Your password and confirmation password do not match.'
                    : null}
                </p>
              )}
            </div>
          </div>
        )}
        <div>
          <div style={{ marginTop: 15 }}>
            {process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY && (
              <ReCAPTCHA ref={captchaRef} sitekey="6LfCFOgqAAAAAIMGKNWYF9caN15pFpg615POboPz" />
            )}
          </div>
          <button
            disabled={isSubmitForm && !isDisabled && isPreRegistration}
            onClick={() => {
              setIsSubmitForm(true);
              if (!isPreRegistration) {
                if (isDisabled) setActive(1);
                else handleSubmit();
              }
            }}
            className="btn_registration_submit"
          >
            Continue
          </button>
          <Grid className={classes.hLoginFormOR}>
            <Typography variant="body1">
              <span>OR</span>
            </Typography>
          </Grid>
          <Grid className={classes.loginButton}>
            <Button onClick={() => handleGoogleLogin()} variant="outlined" endIcon={<FcGoogle size={30} />}>
              Register With
            </Button>
            {/* <Button variant="outlined" onClick={() => handleSsoLoginMicrosoft()} endIcon={<MicroSoftIcon />}>
              Register with
            </Button> */}
            {!isComponentLoaded && (
              <>
                <Button variant="outlined" disabled={true} endIcon={<MicroSoftIcon />}>
                  Register with
                </Button>
              </>
            )}
            <div className="microsoft-sso-login-button-class" id="microsoft-sso-login-button"></div>
          </Grid>
        </div>
      </div>
    </div>
  );
};
export default PreRegistrationForm;
