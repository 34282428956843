import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { Layer, Input, Button } from '../common';
import { labelStyle, iconStyle, fileInputStyle, defaultButtonStyle } from '../styles/default';
import { acceptedIMGFileTypes, titles } from '../config';
import { GoFile } from 'react-icons/go';
import { useCreateIntegrantTypeStore } from '../hooks/useCreateIntegrantTypeStore';
import { isEmptyString, validURL } from '../utils';
import { GiCloudUpload } from 'react-icons/gi';
import { makeStyles, Theme, createStyles, Grid, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useDebounce } from 'hooks/useDebounce';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    selectRoot: {
      '&>option[value=""][disabled]': {
        display: 'none'
      },
      '&>option': {
        color: '#000'
      }
    },
    selectPlaceholderStyle: {
      color: '#666'
    },
    selectErrorStyle: {
      border: '1px solid red'
    },
    productImage: {
      height: 'max-content',
      maxHeight: 200,
      width: '100%',
      '&>img': {
        width: '100%',
        height: 'max-content',
        maxHeight: 200,
        objectFit: 'contain'
      }
    },
    replaceImageLabel: {
      marginTop: 10
    }
  })
);

interface IIntegrantDetailsProps {
  isVisible: boolean;
  ingredient?: any;
  type?: string;
}

const IntegrantTypeDetails: FunctionComponent<IIntegrantDetailsProps> = ({
  isVisible,
  ingredient = null,
  type = ''
}): JSX.Element => {
  const classes = useStyle();
  let {
    integrantType,
    onUpdateDetails,
    setFile,
    currentFile,
    verifyIngredientStaticBatchId,
    isSubmitForm,
    setProductLabel,
    productLabel
  } = useCreateIntegrantTypeStore();
  const [videoLinkError, setVideoLinkError] = useState<boolean>(false);
  const { debounceData } = useDebounce(ingredient?.external_id || '', 500);
  let external_id = integrantType.external_id !== null ? integrantType.external_id : '';
  let productImageInputId = 'file';
  let productLabelImageInputId = 'product_label_file';
  let isValidBatchId = false;
  if (ingredient) {
    currentFile = ingredient?.currentFile;
    productImageInputId = `file-${ingredient?.id}`;
    integrantType = ingredient;
    external_id = ingredient?.external_id !== null ? ingredient?.external_id : '';
    isValidBatchId =
      Boolean(ingredient) && Boolean(external_id) && !ingredient?.isUniqueBatchId && ingredient?.isBatchIdVerified;
  }

  const handleFiles = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files.length !== 0) {
      setFile(event.target.files[0], ingredient);
    }
  };
  const handleProductLabelFiles = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files !== null && event.target.files.length !== 0) {
      setProductLabel(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (debounceData?.trim() && ingredient) {
      verifyIngredientStaticBatchId(ingredient);
    }
  }, [debounceData]);

  const renderFileUpload = (): ReactNode => (
    <>
      <input
        style={{ ...fileInputStyle }}
        accept={acceptedIMGFileTypes}
        onChange={handleFiles}
        type="file"
        id={productImageInputId}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <label htmlFor={productImageInputId} className={clsx('integrant-type-image-label')}>
            <div>
              <GoFile style={iconStyle}></GoFile>{' '}
              {currentFile !== null
                ? typeof currentFile === 'string'
                  ? currentFile?.split('/')?.pop()
                  : currentFile?.name
                : `Choose a file`}
            </div>
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Box display="flex" alignItems="center" justifyContent="center" className={classes.productImage}>
            {currentFile !== null ? (
              <img
                src={typeof currentFile === 'string' ? currentFile : URL.createObjectURL(currentFile)}
                alt="Product-image"
              />
            ) : (
              ''
            )}
          </Box>
          {type === 'importNIHProduct' && currentFile && (
            <label
              htmlFor={productImageInputId}
              className={clsx('integrant-type-image-label', classes.replaceImageLabel)}
            >
              <div>
                <GoFile style={iconStyle}></GoFile> Replace Image
              </div>
            </label>
          )}
        </Grid>
      </Grid>
    </>
  );

  const renderProductFileUpload = (): ReactNode => (
    <>
      <input
        // style={{ ...fileInputStyle }}
        style={{ display: 'none' }}
        accept={acceptedIMGFileTypes}
        onChange={handleProductLabelFiles}
        type="file"
        id={productLabelImageInputId}
      />
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          <label htmlFor={productLabelImageInputId} className={clsx('integrant-type-image-label')}>
            <div>
              <GoFile style={iconStyle}></GoFile>{' '}
              {productLabel !== null && productLabel !== undefined
                ? typeof productLabel === 'string'
                  ? productLabel?.split('/')?.pop()
                  : productLabel?.name
                : `Choose a file`}
            </div>
          </label>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          {/* <Box display="flex" alignItems="center" justifyContent="center" className={classes.productImage}>
            {productLabel !== null && productLabel !== undefined ? (
              <img
                src={typeof productLabel === 'string' ? productLabel : URL.createObjectURL(productLabel)}
                alt="Product-image"
              />
            ) : (
              ''
            )}
          </Box> */}
          {type === 'importNIHProduct' && productLabel && (
            <label
              htmlFor={productLabelImageInputId}
              className={clsx('integrant-type-image-label', classes.replaceImageLabel)}
            >
              <div>
                <GoFile style={iconStyle}></GoFile> Replace Image
              </div>
            </label>
          )}
        </Grid>
      </Grid>
    </>
  );
  return isVisible ? (
    <Layer direction="column" fill scrollY>
      {/* <Button style={{ ...defaultButtonStyle, width: '100%' }}>
        <GiCloudUpload style={iconStyle} /> Upload CSV
      </Button> */}
      <h5 style={labelStyle}>
        {ingredient ? titles.subIntegrant : titles.integrantType} Image<span className="asterisk-symbol">*</span>
      </h5>
      {renderFileUpload()}
      <h5 style={labelStyle}>
        Product Label<span className="asterisk-symbol">*</span>
      </h5>
      {renderProductFileUpload()}
      <h5 style={labelStyle}>
        Details<span className="asterisk-symbol">*</span>
      </h5>
      <Input
        value={integrantType.title}
        required={(ingredient ? ingredient?.isSubmitForm : isSubmitForm) && isEmptyString(integrantType.title)}
        placeholder="Title"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
          onUpdateDetails('title', evt.currentTarget.value, ingredient)
        }
      />
      <Input
        value={integrantType.description}
        required={(ingredient ? ingredient?.isSubmitForm : isSubmitForm) && isEmptyString(integrantType.description)}
        placeholder="Description"
        style={{ borderTop: 0 }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
          onUpdateDetails('description', evt.currentTarget.value, ingredient)
        }
      />
      <h5 style={labelStyle}>Static Batch No.</h5>
      <Input
        value={external_id}
        placeholder="Batch No"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
          onUpdateDetails('external_id', evt.currentTarget.value?.replace(' ', ''), ingredient)
        }
        required={isValidBatchId}
      />
      {isValidBatchId && (
        <p className="input-helper-text" style={{ marginTop: 5 }}>
          Static Batch Number Already Exists.
        </p>
      )}
      <h5 style={labelStyle}>
        Directions<span className="asterisk-symbol">*</span>
      </h5>
      <Input
        value={integrantType.directions}
        required={(ingredient ? ingredient?.isSubmitForm : isSubmitForm) && isEmptyString(integrantType.directions)}
        placeholder="Directions"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
          onUpdateDetails('directions', evt.currentTarget.value, ingredient)
        }
      />
      <h5 style={labelStyle}>Warnings</h5>
      <Input
        value={integrantType.warnings}
        //required={isEmptyString(integrantType.warnings)}
        placeholder="Warnings"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void =>
          onUpdateDetails('warnings', evt.currentTarget.value, ingredient)
        }
      />
      <h5 style={labelStyle}>
        Sales Channel<span className="asterisk-symbol">*</span>
      </h5>
      <select
        value={integrantType.product_type}
        onChange={(evt: any): void => onUpdateDetails('product_type', evt.target.value, ingredient)}
        className={clsx(
          classes.selectRoot,
          !integrantType.product_type && classes.selectPlaceholderStyle,
          (ingredient ? ingredient?.isSubmitForm : isSubmitForm) &&
            isEmptyString(integrantType.product_type) &&
            classes.selectErrorStyle
        )}
      >
        <option value={''} disabled>
          Select Sales Channel
        </option>
        <option value={'Business to Business'}>Business to Business</option>
        <option value={'Business to Consumer'}>Business to Consumer</option>
      </select>
    </Layer>
  ) : (
    <div />
  );
};

export { IntegrantTypeDetails };
